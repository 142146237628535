<div class="modal fade"
     id="profileModal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="profileModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-slideout right-slider modal-lg d-flex flex-column justify-content-between d-flex flex-column" role="document" id="modalForProfile">
        <div class="modal-content flex-grow-1">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>

            <div class="modal-body d-flex flex-column flex-grow-1">
                <div class="mr-auto px-1 px-md-5">
                    <div class="mb-4">
                        <h2>{{ 'profile-modal.title' | translate }}</h2>
                    </div>

                    <div class="info-wrapper mb-4">
                        <div class="info-field">
                            <div class="info-field-label">{{ 'profile-modal.info-field.label.phone' | translate }}</div>
                            <div class="info-field-content">
                                <a href="tel:{{ profile.phone }}">{{ formatPhoneNumber(profile.phone) }}</a>
                            </div>
                        </div>

                        <div class="info-field">
                            <div class="info-field-label">{{ 'profile-modal.info-field.label.user-id' | translate }}</div>
                            <div class="info-field-content" (click)="copyUserId()">
                                <div class="profile-id">{{ profile.id }}</div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="success-copy-message fade active"
                        [ngClass]="{ show: isCopied }">
                        {{ 'profile-modal.success-copy' | translate }}
                    </div>
                </div>
            </div>

            <div class="modal-footer border-0 d-flex flex-column align-items-start">
                <div class="mb-4 ml-4">
                    <img src="{{ currentLogoBlackURL }}" alt="Brand logo" width="240" height="60"/>
                </div>
                <div class="d-flex">
                    <div class="profile-modal-button">{{ 'profile-modal.button.withdraw-consent' | translate }}</div>
                    <div class="profile-modal-button" (click)="deleteAccount()">{{ 'profile-modal.button.delete-profile' | translate }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
