import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { LocalizationService } from '../localization/service/localization.service';
import { SubSink } from '../localization/utils';
declare var $: any;

const SUCCESS_COPY_MESSAGE_ACTIVE_TIMEOUT = 2000;

interface ProfileType {
    id: string;
    phone: string;
}

@Component({
    selector: 'app-profile-modal',
    templateUrl: './profile-modal.component.html',
    styleUrls: ['./profile-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileModalComponent implements OnInit, OnDestroy {
    public readonly subSink: SubSink = new SubSink();

    public profile: ProfileType = {
        id: '1gh23h1gb42jh3gb1lkh23b1',
        phone: '72222222222',
    };
    // TODO: это нужно?
    public badRequest: string;
    public isCopied = false;
    public currentLogoBlackURL: string;

    constructor(
        private readonly localizationService: LocalizationService,
        private cdr: ChangeDetectorRef,
    ) {
        this.subSink.sink = this.localizationService.languageChanged.subscribe(() => {
            this.currentLogoBlackURL = this.localizationService.getMessage('app.currentLogoBlackURL');

            cdr.markForCheck();
        });
    }

    ngOnInit(): void {
        this.subSink.sink = this.localizationService.languageChanged.subscribe(() => {
            // TODO: добавить локализацию, если потребуется
        });

        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }

    formatPhoneNumber(phoneNumber: string) {
        return phoneNumber.replace(/^7(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7($1) $2-$3-$4');
    }

    copyUserId() {
        navigator.clipboard.writeText(this.profile.id)
            .then(() => {
                this.isCopied = true;
                this.cdr.detectChanges();

                setTimeout(() => {
                    this.isCopied = false;
                    this.cdr.detectChanges();
                }, SUCCESS_COPY_MESSAGE_ACTIVE_TIMEOUT);
            })
            .catch(err => {
                console.error('Ошибка при копировании текста: ', err);
            });
    }

    deleteAccount() {
        $('#profileModal').modal('hide');
        $('#confirmDeleteUserDialog').modal('show');
    }
}
