import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {LocalizationService} from 'src/app/localization/service/localization.service';
import {CreateIssueRequest} from 'src/app/ride-history/domain/create-issue-request';
import {PassesListPart} from 'src/app/ride-history/domain/passes-list-part';
import {
    OnboardingLinkResponseType,
    PaymentLinkRegisterResponseType,
    ProfileInfoResponseType
} from 'src/app/ride-history/domain/profile';
import { catchError } from 'rxjs/operators';
import {PsListRequest} from 'src/app/ride-history/domain/ps-list-request';
import { HandleError, HttpErrorHandler } from '../../http/http-error-handler.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RideHistoryService {
    private readonly handleError: HandleError;

    constructor(
        private readonly http: HttpClient,
        private readonly httpErrorHandler: HttpErrorHandler,
        private readonly localizationService: LocalizationService,
    ) {
        this.handleError = httpErrorHandler.createHandleError('RideHistoryService');
    }

    public getProfileInfo(): Observable<ProfileInfoResponseType> {
        return this.http
            .get<ProfileInfoResponseType>('/profile/info')
            .pipe(catchError(this.handleError('getProfileInfo', null)));
    }

    public getOnboardingLink(): Observable<OnboardingLinkResponseType> {
        return this.http
            .get<OnboardingLinkResponseType>('/profile/onboarding_link')
            .pipe(catchError(this.handleError('getOnboardingLink', null)));
    }

    public addBankCard(paymentLinkId: string): Observable<PaymentLinkRegisterResponseType> {
        return this.http
            .get<PaymentLinkRegisterResponseType>(`/profile/payment_links/${paymentLinkId}/status`)
            .pipe(catchError(this.handleError('addBankCard', null)));
    }

    // TODO: ТИПИЗИРОВАТЬ ТУТ ВСЕ
    public getCardList(): Observable<any> {
        return this.http
            // TODO: Убрать фейковый запрос в профайл, когда бэкенд разработают функционал
            // .get<any>('/tp/cards', {})
            .get<ProfileInfoResponseType>('/profile/info')
            .pipe(catchError(this.handleError('getCardList', [])));
    }

    public startToLinkCard(): Observable<any> {
        const language = this.localizationService.currentLanguage.toUpperCase();
        return this.http
            .get<any>(`/tp/link/create?language =${language}`, {})
            .pipe(catchError(this.handleError('startToLinkCard', [])));
    }

    public getLinkCardStatus(id: string): Observable<any> {
        return this.http.post('/tp/link/status', { id }).pipe(catchError(this.handleError('getLinkCardStatus', [])));
    }

    public deleteCard(id: string): Observable<any> {
        return this.http.delete('/tp/link/delete/' + id).pipe(catchError(this.handleError('deleteCard', [])));
    }

    public makeCardFavorite(id: string): Observable<any> {
        return this.http
            .put('/tp/cards/make/favourite/' + id, {})
            .pipe(catchError(this.handleError('makeCardFavorite', [])));
    }

    public startToPayDebtOnCard(card: string): Observable<any> {
        const language = this.localizationService.currentLanguage.toUpperCase();
        return this.http
            .post<any>('/tp/repayment/start', { card, language })
            .pipe(catchError(this.handleError('startToPayDebtOnCard', [])));
    }

    public getPayDebtCardStatus(id: string): Observable<any> {
        return this.http
            .post('/tp/repayment/status', { transaction: id })
            .pipe(catchError(this.handleError('getPayDebtCardStatus', [])));
    }

    public getPassesListPart(passesListRequest: PsListRequest): Observable<PassesListPart> {
        return this.http
            // TODO: Убрать фейковый запрос в профайл, когда бэкенд разработают функционал
            // .post<any | PassesListPart>('/tp/cards/passes', passesListRequest)
            .get<any>('/profile/info')
            .pipe(catchError(this.handleError('getPassesListPart', [])));
    }

    public createIssue(createIssueRequest: CreateIssueRequest): Observable<any> {
        return this.http
            .post<any>('/tp/issues/create', createIssueRequest)
            .pipe(catchError(this.handleError('createIssue', [])));
    }

    public getLinkedPassByTravelId(payload): Observable<any> {
        return this.http
            .post<any>('/tp/card/pass/travel', payload)
            .pipe(catchError(this.handleError('getLinkedPassByTravelId', [])));
    }

    public downloadPassesListPart(passesListRequest: PsListRequest): Observable<any> {
        passesListRequest['responseFormat'] = 'EXCEL';
        passesListRequest['language'] = this.localizationService.currentLanguage.toUpperCase();
        return this.http
            .post<any>('/tp/cards/passes', passesListRequest, {
                observe: 'response',
                responseType: 'blob' as 'json',
            })
            .pipe(catchError(this.handleError('downloadPassesListPart', [])));
    }
}
