import { Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent } from 'rxjs';
import { LocalizationService } from '../../../localization/service/localization.service';
import { SubSink } from '../../../localization/utils';
import { AuthService } from '../../../security/service/auth.service';
import { ConfigService } from '@ngx-config/core';
declare var $: any;

@Component({
    selector: 'app-main-view',
    templateUrl: './main-view.component.html',
    styleUrls: ['./main-view.component.scss'],
})
export class MainViewComponent implements OnInit, OnDestroy {
    public events: [];
    private idToScroll: string;
    protected readonly subSink: SubSink = new SubSink();

    constructor(
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private readonly localizationService: LocalizationService,
        public readonly authService: AuthService,
        private readonly configService: ConfigService
    ) {
        MainViewComponent.registerEvent('shown.bs.modal');
        MainViewComponent.registerEvent('show.bs.modal');
        this.subSink.sink = this.localizationService.languageChanged.subscribe(() => {});
    }

    private static registerEvent(event) {
        const script = document.createElement('script');
        script.innerHTML = 'eventRelay(\'' + event + '\');';
        document.body.appendChild(script);
    }

    ngOnInit(): void {
        this.events = this.configService.getSettings('events');

        this.renderer.addClass(this.document.body, 'bg-default');
        $('.modal-backdrop').remove();
        fromEvent(document, 'shown.bs.modal').subscribe(() => {
            const el: HTMLElement = document.getElementById(this.idToScroll);
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        });
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(this.document.body, 'bg-default');
        this.subSink.unsubscribe();
    }

    scroll(id: string): void {
        this.idToScroll = id;
    }
}
