import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import emailMask from 'text-mask-addons/dist/emailMask';
import { fromEvent, interval, Subscription } from 'rxjs';
import { AuthService } from '../../../security/service/auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LocalizationService } from '../../../localization/service/localization.service';
import { SubSink } from '../../../localization/utils';
import { RegistrationComponent } from '../../../registration/view/registration/registration.component';
import { Constants } from '../../../constants/Constants';
import { ConfigService } from '@ngx-config/core';
declare var $: any;

@Component({
    selector: 'app-login-registration',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    @ViewChild('passwordInput') passwordInput: ElementRef<HTMLInputElement>;
    @ViewChild('codeFrom') codeFrom: ElementRef;
    public formProcessing = false;
    public loginForm: FormGroup;
    public checkAccount: FormGroup;
    public passwordRecoveryBlockSwitched = false;
    public alreadySetInterval = false;
    public passwordShown = false;
    public aboutMugBlockSwitched = false;
    public showMugFooter = true;
    public showMugFooterInReg = false;
    public emailMask = emailMask;
    public authByPhone = true;
    public readonly subSink: SubSink = new SubSink();
    public wrongLoginPasswordMessage: string;
    public accountLockedMessage: string;
    public checkExpiredOtpMessage: string;
    public checkErrorOtpMessage: string;
    public errorOtpRepeatMessage: string;
    public checkBlockedOtpMessage: string;
    public activeCodeOtp: string;
    public activeCodeOtpText: string;
    public isRepeatCode = false;
    public blockBtn = false;
    public badRequest: string;
    public activeTab = 'login';
    public regStep = 1;
    public regCapchaChecked = false;
    public accountLockPeriod = false;
    public recCapchaChecked = false;
    public isEnabledEmail = true;
    private sub: Subscription;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly authService: AuthService,
        private readonly router: Router,
        private messageService: MessageService,
        private readonly localizationService: LocalizationService,
        private readonly configService: ConfigService,
    ) {}

    ngOnInit(): void {
        const { auth } = this.configService.getSettings('enableEmail');
        this.isEnabledEmail = auth;

        this.subSink.sink = this.localizationService.languageChanged.subscribe(() => {
            this.wrongLoginPasswordMessage = this.localizationService.getMessage('login.wrongLoginPasswordMessage');
            this.accountLockedMessage = this.localizationService.getMessage('login.accountLockedMessage');
            this.checkExpiredOtpMessage = this.localizationService.getMessage('login.checkExpiredOtpMessage');
            this.checkErrorOtpMessage = this.localizationService.getMessage('login.checkErrorOtpMessage');
            this.errorOtpRepeatMessage = this.localizationService.getMessage('login.errorOtpRepeatMessage');
            this.checkBlockedOtpMessage = this.localizationService.getMessage('login.checkBlockedOtpMessage');
            this.activeCodeOtp = this.localizationService.getMessage('login.activeCodeOtp');
            this.badRequest = this.localizationService.getMessage('badRequest');
        });
        this.loginForm = this.formBuilder.group({
            phoneLogin: ['', Validators.required],
            emailLogin: [
                '',
                [Validators.required, Validators.pattern(Constants.email)],
            ],
            password: ['', Validators.required],
        });

        this.checkAccount = this.formBuilder.group({
          otpCode: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
        });

        fromEvent(document, 'show.bs.modal').subscribe(($event) => {
            this.passwordRecoveryBlockSwitched = false;
            this.aboutMugBlockSwitched = false;
            this.loginForm.reset();
            this.messageService.clear();
            this.activeTab = 'login';
            this.showMugFooter = true;
            this.regStep = 1;
            this.showMugFooterInReg = false;
            this.regCapchaChecked = false;
            this.recCapchaChecked = false;
            if (this.sub) {
                this.clearMessageService();
            }
        });

        // autofill workaround
        this.loginForm.valueChanges.subscribe(({ password }) => {
            if (!password && !this.loginForm.controls['password'].touched) {
                this.loginForm.controls['password'].reset('', { emitEvent: false });
            }
        });
    }

    public onLoginFormSubmit(): void {
        const login = this.authByPhone ? 'phoneLogin' : 'emailLogin';
        this.loginForm.controls[login].markAsDirty();
        this.loginForm.controls['password'].markAsDirty();
        this.isRepeatCode = false;

        if (this.passwordInput.nativeElement.value && !this.loginForm.controls['password'].value) {
            this.loginForm.controls['password'].setValue(this.passwordInput.nativeElement.value);
        }
        if (!this.isValid()) { return; }

        this.formProcessing = true;
        const value = this.loginForm.value;
        if (this.sub) {
            this.clearMessageService();
        }

        const showExtraMessageAfterLogin = this.configService.getSettings('showExtraMessageAfterLogin');

        const loginObserver = {
            next: () => {
                $('#authRegModal').modal('hide');
                const routeAfterLogin = sessionStorage.getItem('routeAfterLogin');
                this.router.navigate(routeAfterLogin ? [routeAfterLogin] : ['/ride-history'])
                    .then(() => {
                        this.formProcessing = false;
                        if (showExtraMessageAfterLogin) {
                            this.authService.dataStream.next(3);
                        }
                    });
            },
            error: (errorData) => {
                let errorMessage;
                this.messageService.clear();
                if (errorData.error.status === 'WRONG_LOGIN_PASSWORD') {
                    if (errorData.error.accountLockTime) {
                      this.activeCodeOtpText = '';
                      this.clearMessageService();
                      this.accountLockPeriod = true;
                      errorMessage = this.accountLockedMessage.replace(
                        'otpLockTime',
                        RegistrationComponent.getStringTimerValue(errorData.error.accountLockRemainingPeriod),
                      );
                      // @ts-ignore
                      // tslint:disable-next-line:max-line-length
                      this.startAccountLockTimer((errorData.error.otpLockTime - Date.parse(new Date())), this.activeCodeOtp, 'otpLockTime');
                    } else {
                        errorMessage = this.wrongLoginPasswordMessage;
                    }
                } else {
                    errorMessage = this.badRequest;
                }
                this.messageService.add({
                    severity: 'error',
                    summary: '',
                    detail: errorMessage,
                    sticky: true,
                });
                this.formProcessing = false;
            },
        };
        if (this.authByPhone) {
            this.subSink.sink = this.authService
                .loginByMsisdn(value.phoneLogin, value.password)
                .subscribe(loginObserver);
        } else {
            this.subSink.sink = this.authService
                .loginByEmail(value.emailLogin, value.password)
                .subscribe(loginObserver);
        }
    }

    public showPassword(): void {
        this.passwordShown = !this.passwordShown;
    }

    public onRepeatOtpCode(): void {
      const checkAccount = {
        next: (data) => {
          this.activeCodeOtpText = '';
          this.clearMessageService();
          this.blockBtn = false;
          this.isRepeatCode = false;
          // @ts-ignore
          // tslint:disable-next-line:max-line-length
          this.startAccountLockTimer((data.otpLockTime - Date.parse(new Date())), this.activeCodeOtp, 'otpLockTime');
        },
        error: (errorData) => {
          let errorMessage;
          this.messageService.clear();
          if (errorData.error?.status === 'ERROR') {
            this.accountLockPeriod = true;
            errorMessage = this.errorOtpRepeatMessage;
            this.isRepeatCode = false;
          } else {
            errorMessage = this.badRequest;
          }
          this.messageService.add({
            severity: 'error',
            summary: '',
            detail: errorMessage,
            sticky: true,
          });
          this.formProcessing = false;
        },
      };

      this.authService
        .loginInnersessionOtp(this.loginForm.controls['phoneLogin'].value)
        .subscribe(checkAccount);
    }

    public onCheckFormSubmit(): void {
      const value = this.checkAccount.value;

      const showExtraMessageAfterLogin = this.configService.getSettings('showExtraMessageAfterLogin');

      const checkAccount = {
        next: (data) => {
          if (data.payload.sessionToken) {
            this.accountLockPeriod = false;
            this.checkAccount.reset();
            this.loginForm.controls['password'].reset();
            $('#authRegModal').modal('hide');
            const routeAfterLogin = sessionStorage.getItem('routeAfterLogin');
            this.router.navigate(routeAfterLogin ? [routeAfterLogin] : ['/ride-history'])
              .then(() => {
                this.formProcessing = false;
                if (showExtraMessageAfterLogin) {
                  this.authService.dataStream.next(3);
                }
              });
          } else {
            this.accountLockPeriod = false;
            this.loginForm.controls['password'].reset();
            this.checkAccount.reset();
          }

        },
        error: (errorData) => {
          let errorMessage;
          this.messageService.clear();
          if (errorData.error?.status === 'EXPIRED') {
            this.accountLockPeriod = true;
            errorMessage = this.checkExpiredOtpMessage;
            this.activeCodeOtpText = '';
            this.isRepeatCode = false;
            this.clearMessageService();
            // @ts-ignore
            // tslint:disable-next-line:max-line-length
            this.startAccountLockTimer((Date.parse(errorData.error.otpLockTime) - Date.parse(new Date())), this.activeCodeOtp, 'otpLockTime');
          } else if (errorData.error?.status === 'ERROR') {
            this.accountLockPeriod = true;
            this.isRepeatCode = false;
            errorMessage = this.checkErrorOtpMessage;
            this.activeCodeOtpText = '';
            this.clearMessageService();
            // @ts-ignore
            // tslint:disable-next-line:max-line-length
            this.startAccountLockTimer((Date.parse(errorData.error.otpLockTime) - Date.parse(new Date())), this.activeCodeOtp, 'otpLockTime');
          } else if (errorData.error?.status === 'BLOCKED') {
            this.accountLockPeriod = true;
            this.isRepeatCode = false;
            this.blockBtn = true;
            errorMessage = this.checkBlockedOtpMessage;
            this.activeCodeOtpText = '';
            this.clearMessageService();
            // @ts-ignore
            // tslint:disable-next-line:max-line-length
            this.startAccountLockTimer((Date.parse(errorData.error.otpLockTime) - Date.parse(new Date())), this.activeCodeOtp, 'otpLockTime');
          } else {
            errorMessage = this.badRequest;
          }
          this.messageService.add({
            severity: 'error',
            summary: '',
            detail: errorMessage,
            sticky: true,
          });
          this.formProcessing = false;
        },
      };

      this.authService
        .loginInnersessionConfirm(this.loginForm.controls['phoneLogin'].value, value.otpCode)
        .subscribe(checkAccount);
    }

    isValid() {
        return this.authByPhone
            ? this.loginForm.controls['phoneLogin'].valid && this.loginForm.controls['password'].valid
            : this.loginForm.controls['emailLogin'].valid && this.loginForm.controls['password'].valid;
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }

    switchTo(tab: string) {
        if (this.activeTab === tab) {
            return;
        }
        this.activeTab = tab;
        this.showMugFooter = tab === 'login';
        this.regCapchaChecked = false;
        this.showMugFooterInReg = tab !== 'login' && this.regStep === 3;
        this.clearMessageService();
    }

    private startAccountLockTimer(accountLockPeriod: number, message: string, messageKey: string) {
        let currentTimerValue = accountLockPeriod;
        if (!this.alreadySetInterval) {
            this.sub = interval(1000).subscribe((val) => {
                currentTimerValue = currentTimerValue - 1000;
                if (currentTimerValue < 0) {
                    this.activeCodeOtpText = '';
                    this.isRepeatCode = true;
                    return;
                }
                const accountLockedMessage = message.replace(
                    messageKey,
                    RegistrationComponent.getStringTimerValue(currentTimerValue),
                );
                this.activeCodeOtpText = accountLockedMessage;
            });
        }
    }

    clearMessageService() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.alreadySetInterval = false;
        this.messageService.clear();
    }

    toRecoveryBlock() {
        this.clearMessageService();
        this.passwordRecoveryBlockSwitched = true;
    }

    closeOtpCheckCode() {
       this.accountLockPeriod = false;
       this.loginForm.controls['password'].reset();
       this.checkAccount.reset();
    }
}
